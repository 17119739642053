.MainBody {
  display: flex;
  flex: auto;

  .RenderView {
    display: flex;
    flex-direction: column;
    flex: auto;
    // width: 90vw;
    height: 98vh;
  }

  .information {
    display: flex;
    // width:100%;
    .info {
      display: flex;
    }

    .infoLabel {
      background: #808080;
      text-align: center;
    }

    .inforValue {
      background: #ddd;
      text-align: center;
    }

    div {
      flex: 1 1 auto;
      padding: 5px;
      // height: 24px;
      border-radius: 10px;
    }
  }

  .Scene {
    position: relative;
    flex: 1;

    canvas {
      // pointer-events: none;
      position: absolute;
    }

    .layers,
    .houses {
      position: absolute;
      left: 10px;
      top: 10px;
      overflow: auto;
      height: 500px;
      z-index: 1;
      ul {
        margin: 0px;
        padding: 0px;
      }

      li {
        background-color: #909090;
        border: 2px solid #ddd;
        color: #ffffff;
        text-align: center;
        list-style: none;
        cursor: pointer;
        // width: 100px;
      }
    }
    .mapSearch{
      visibility: hidden;
      position: absolute;
      left: 10px;
      top: 10px;
      overflow: auto;
      z-index: 1;
    }
    .extraMenu {
      position: absolute;
      left: 10px;
      top: 550px;
      color: #ffffff;
      z-index: 1;
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        width: 50px;
      }
    }
  }

  .tablist {
    display: flex;
    flex-direction: column;
    background-color: #909090;
    overflow: auto;

    width: 10vw;
    height: 98vh;
    button {
      height: 40px;
      width: 100%;
      text-align: center;
    }

    input[type="file"] {
      width: -webkit-fill-available;
      padding: 0;
    }
    
    input[type="range"] {
      width:-webkit-fill-available;
    }

    input[type="number"] {
      width:-webkit-fill-available;
    }

    .list {
      ul {
        margin: 0px;
        padding: 0px;
      }

      li {
        border: 2px solid #ddd;
        color: #ffffff;
        list-style: none;
        cursor: pointer;
      }

      .li2D {
        border: 2px solid #ddd;
        color: #ffffff;
        list-style: none;
        cursor: pointer;
        pointer-events: none;
      }
      .levelDiv {
        input{
          width: 50px;
        }
      }

      button {
        height: 30px;
        width: 100%;
      }
      span {
        position: relative;
        right: 0;
        color: red;
        cursor: pointer;
      }
      span::before {
        content: "";
      }
      .buildingName {
        color: white;
      }

    }

    span {
      cursor: pointer;
      color: blue;
    }
    span::before {
      content: "\25B6";
    }
    .treeOpen::before {
      transform: rotate(90deg);
    }
  }
}

header {
  width: 100vw;
  height: 2vh;
  text-align: center;
  background-color: gray;
}
